import Logo from "./logo";
import Text from "./text";

const Logotext = () => {
    return (
        <div className="animated">
            <Logo />
            <Text />
        </div>
    );
}

export default Logotext;