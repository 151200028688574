import React, { useEffect, useRef } from 'react';
import Video from "./img/video.mp4"

const MainText = () => {
  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.playbackRate = 0.75; 
    }
  }, []);

  return (
    <div className='main-text'>
      <video autoPlay muted loop ref={videoRef}>
        <source
                src={Video}
                type="video/mp4"
                />
      </video>
      <h2 style={{textAlign:'center'}}>TMP ENGINEERING <br /> site is under <br /> construction
      </h2>
    </div>
  );
};

export default MainText;