import './App.css';
import Logotext from './components/logotext';
import Phrases from './components/MainText';
import SimpleSlide from './components/icons';
import CopyrightIcon from '@mui/icons-material/Copyright';


function App() {
  return (
    <div style={{
      display: 'flex',
      flexDirection:"column"
    }}>
      <div>
        <Logotext/>
      </div>
      <div id='icon-text' style={{ display:"flex",  justifyContent:"space-between" }}>
        <Phrases/>
        <SimpleSlide />
      </div>
      <div className='by' style={{ display: 'flex', alignItems: 'center', gap:"10px" }}>
  <CopyrightIcon fontSize='medium'/>
  <p style={{ margin: 0 }}>   By <a id='author' style={{textDecoration:"none", color:"#999", fontSize:'1.25rem'}} rel="noopener noreferrer" href='https://www.linkedin.com/in/2rall/'>Tural Shakarli</a></p>
</div>
    </div>
  );
}

export default App;
