import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Slide from '@mui/material/Slide';

import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';

const icon = (
  <Paper sx={{ m: 1, width: 100, height: 100, bgcolor: 'transparent' }} elevation={0}>
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        bgcolor: 'transparent',
      }}
    >
      <a rel="noopener noreferrer"  target="_blank" href="https://www.linkedin.com/in/tural-ehmed-5a20a223a/">
        <LinkedInIcon sx={{ fill: "#999", fontSize: 40, transition: 'transform 0.3s ease-in-out', '&:hover': { transform: 'scale(3)' } }}/>
      </a>
    </Box>
  </Paper>
);
const icon2 = (
  <Paper sx={{ m: 1, width: 100, height: 100, bgcolor: 'transparent' }} elevation={0}>
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        bgcolor: 'transparent',
      }}
    >
      <a rel="noopener noreferrer" target="_blank" href="https://www.instagram.com/_tural_ahmed?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==">
        <InstagramIcon sx={{ fill: "#999", fontSize: 40, transition: 'transform 0.3s ease-in-out', '&:hover': { transform: 'scale(3)' } }}/>
      </a>
    </Box>
  </Paper>
);


const icon3 = (
  <Paper sx={{ m: 1, width: 100, height: 100, bgcolor: 'transparent' }} elevation={0}>
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        bgcolor: 'transparent',
      }}
    >
      <a  href="mailto:tural@tmp.az">
      <EmailIcon sx={{ fill: "#999", fontSize: 40, transition: 'transform 0.3s ease-in-out', '&:hover': { transform: 'scale(3)' } }} />

      </a>
    </Box>
  </Paper>
);
const icon4 = (
  <Paper sx={{ m: 1, width: 100, height: 100, bgcolor: 'transparent' }} elevation={0}>
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        bgcolor: 'transparent',
      }}
    >
      <a rel="noopener noreferrer"  target="_blank"  href="https://maps.app.goo.gl/jejZXchX93J8XhmJ9">
      <LocationOnIcon sx={{ fill: "#999", fontSize: 40, transition: 'transform 0.3s ease-in-out', '&:hover': { transform: 'scale(3)' } }} />

      </a>
    </Box>
  </Paper>
);
 
const icon0 = (
  <Paper sx={{ m: 1, width: 100, height: 100, bgcolor: 'transparent' }} elevation={0}>
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        bgcolor: 'transparent',
      }}
    >
      <a rel="noopener noreferrer" href="tel:+994508898519">
      <PhoneIcon sx={{ fill: "#999", fontSize: 40, transition: 'transform 0.3s ease-in-out', '&:hover': { transform: 'scale(3)' } }} />

      </a>
    </Box>
  </Paper>
);
 
export default function SimpleSlide() {
  const [checked, setChecked] = React.useState(true);

  return (
    <Box
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: { xs: 'row', md: 'column' },
      width:"150px",
      gap:'1.5rem',
      bgcolor: 'transparent',
    }}
    >
      <Slide direction="up" in={checked} mountOnEnter unmountOnExit timeout={{ enter: 1000, exit: 500 }}>
        {icon0}
      </Slide>
      <Slide direction="up" in={checked} mountOnEnter unmountOnExit timeout={{ enter: 1000, exit: 500 }}>
        {icon}
      </Slide>
      <Slide direction="down" in={checked} mountOnEnter unmountOnExit timeout={{ enter: 1000, exit: 500 }}>
        {icon2}
      </Slide>
      <Slide direction="left" in={checked} mountOnEnter unmountOnExit timeout={{ enter: 1000, exit: 500 }}>
        {icon3}
      </Slide>
      <Slide direction="left" in={checked} mountOnEnter unmountOnExit timeout={{ enter: 1000, exit: 500 }}>
        {icon4}
      </Slide>
      
    </Box>
  );
}